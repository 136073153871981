<template>
  <div
    class="recipe__list-item"
    :class="'categoria-' + item.item.categoria_ricetta[0]"
  >
    <div class="recipe__list-inner">
      <div id="author" class="author" :class="'author-'+authorId">
        <div id="avatar">
          <img :src="avatar" />
        </div>
        <div id="name">
          {{ authorName }}
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="touch-gradient">
        <div class="image">
          <router-link :to="{ name: 'Recipe Details', params: { id: id } }">
            <img :src="item.image" />
          </router-link>
        </div>
        <div class="clearfix"></div>
        <div v-if="item.item.categoria_ricetta[0] != 29">
          <div class="icons">
            <div class="heart posizione1" @click="_like">
              {{ item.item.nr_like[0] }}
              <img v-if="liked == false" src="../../assets/icons/likev.svg" />
              <img v-if="liked == true" src="../../assets/icons/likep.svg" />
            </div>
            <!--<div class="heart posizione2"><img src="../../assets/icons/comment.png"></div>-->
            <!--<div class="heart posizione3"><img src="../../assets/icons/share.png"></div>-->
            <div class="heart posizioneUltima" @click="_bookmark">
              <!--{{ item.item.nr_bookmark[0] }}-->
              <img
                v-if="bookmarked() == true"
                src="../../assets/icons/bookmarkp.svg"
              />
              <img
                v-if="bookmarked() == false"
                src="../../assets/icons/bookmarkv.svg"
              />
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <router-link :to="{ name: 'Recipe Details', params: { id: id } }">
          <div class="title" v-html="title"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import myMixin from '@/components/_mixin/myMixin';

export default {
  name: 'RecipeCardUsers',
  mixins: [myMixin],
  props: ['image', 'title', 'id', 'item','authorName', 'authorId'],
  data() {
    return {
      avatar: '/img/icons/user.svg',
    }
  },
  methods: {
    async _like() {
      const r = await this.like();
      //this.item.item.nr_like[0] = r.nr;
    },
    async like() {
      console.log("like1");
      if (!this.$store.getters['auth/isLoggedIn']) {
        alert("E' necessario effettuare il login per votare questa ricetta");
        return;
      }

      this.loading = true;

      const type = 'ricetta';
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };

      const params = {
        id: this.id,
        type,
      };

      let trovato = this.liked;
      let send = {};
      let type_send = '';

      if (trovato) {
        send = this.axios.delete(
          this.api.like + '?id=' + this.id + '&type=ricetta',
          config,
        );
        type_send = 'delete';
      } else {
        send = this.axios.post(this.api.like, params, config);
        type_send = 'add';
      }

      const r = await send
        .then(async (response) => {
          this.loading = false;
          switch (type_send) {
            case 'add':
              await this.user_data.like.ricetta.push(this.id);
              this.item.item.nr_like[0]++;
              break;

            case 'delete':
              this.user_data.like.ricetta = this.user_data.like.ricetta.filter(
                (item2) => {
                  return parseInt(item2) !== this.id;
                },
              );
              this.item.item.nr_like[0]--;
              break;
          }
          console.log(response.data);
          this.$store.commit('auth/setDataProfile', this.user_data);
        })
        .catch((error) => {
          this.loading = false;
          alert(
            'Operazione al momento non possibile. Si prega di provare tra qualche minuto',
          );
          console.log(error);
        });
    },
    async _bookmark() {
      if (!this.$store.getters['auth/isLoggedIn']) {
        alert(
          "E' necessario effettuare il login per salvare questa ricetta nei preferiti",
        );
        return;
      }
      this.loading = true;

      const params = {
        id: this.id,
        type: 'ricetta',
      };
      let config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        }
      };

      let send = {};
      let send_type = '';
      if (this.bookmarked()) {
        config.data = params;
        send = this.axios.delete(this.api.bookmark, config);
        send_type = 'delete';
      } else {
        send = this.axios.post(this.api.bookmark, params, config);
        send_type = 'add';
      }
      console.log("send type:"+send);
      send
        .then((response) => {
          this.loading = false;
          switch (send_type) {
            case 'add':
              this.user_data.bookmark.ricetta.push(this.id);
              break;

            case 'delete':
              this.user_data.bookmark.ricetta =
                this.user_data.bookmark.ricetta.filter((item) => {
                  return parseInt(item) !== this.id;
                });
              break;
          }
          this.$store.commit('auth/setDataProfile', this.user_data);
        })
        .catch((error) => {
          alert(
            'Operazione al momento non possibile. Si prega di provare tra qualche minuto',
          );
          this.loading = false;
          console.log(error);
        });
    },
    async avatarUser() {
      this.axios
        .get(this.api.getDataProfileOther + this.authorId)
        .then((response) => {
          this.avatar = response.data.avatar
            ? response.data.avatar
            : '/img/icons/user.svg';
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  computed: {
    _liked() {
      return this.liked();
    },
    liked() {
      let trovato = -1;

      if (!this.$store.getters['auth/isLoggedIn']) return false;

      this.user_data.like.ricetta.forEach((item, index) => {
        if (this.id == parseInt(item)) trovato = index;
      });

      if (trovato > -1) return true;
      return false;
    },
  },
  mounted() {
    let isLogged = this.$store.getters['auth/isLoggedIn'];
    this.avatarUser();
  }
};
</script>

<style scoped>
.recipe-card2 {
  display: flex;
  justify-content: center;
  align-items: center;
  widows: 100%;
  height: 95px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 30px;
  border-radius: 9999px;
  overflow: hidden;
}

.recipe-card2 .inner:after {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  height: 98.5%;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(
    315deg,
    rgba(0, 119, 255, 0.4) 0%,
    rgba(255, 0, 62, 0.9) 100%
  );
  mix-blend-mode: multiply;
  transition: 0.3s;
  transform: translateZ(0.0001deg);
  backface-visibility: hidden;
  will-change: opacity;
  border-radius: 9999px;
  overflow: hidden;
}

.recipe-card__category {
  position: absolute;
  margin: 0;
  color: white;
  text-transform: uppercase;
  transition: 0.5s;
  z-index: 9999;
}

.recipe-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

@media all and (max-width: 768px) {
  h3 {
    font-size: 14px !important;
  }
}

.icons {
  font-size: 14px !important;
}

.recipe__list-item {
  width: 100%;
  padding: 0;
  text-align: left;
  float: left;
}

.recipe__list-inner {
  display: block;
  font-size: 1.25rem;
  padding: 5px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
  margin-bottom: 10px;
}

.recipe__list-inner img {
  border: 1px solid #ccc;
  box-shadow: 2px 2px #ccc;
}

.recipe__list-item a {
  text-decoration: none;
}

.recipe__list-inner .title {
  font-size: 0.8rem;
  color: black;
  overflow: hidden;
}

.recipe__list-item img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.icons {
  border: none;
}

.icons .posizioneUltima {
  float: right;
}

.icons img {
  width: 15px;
  height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: none;
  box-shadow: none;
  margin-right: 4px;
  margin-left: 2px;
  margin-bottom: 6px;
}
.icons div {
  float: left;
  border: none;
  display: flex;
}

.categoria-29 .recipe__list-inner {
  border-bottom: none;
}
.categoria-29 .title {
  height: 50px;
  overflow: hidden;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-top: 6px;
}


#author {
  margin-bottom: 2px;
}
#author.author-1 #name {
  color: var(--var-primary-color);
}
#author #avatar {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  text-align: center;
  float: left;
  color: white;
  overflow: hidden;
}

#author #avatar img {
  width: 30px;
  height: 30px;
}

#author #name {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  font-size: 0.8rem;
  color: black;
  font-weight: 900;
  white-space: nowrap;
  transform: translate(5px, 5px);
  overflow: hidden;
}

</style>
